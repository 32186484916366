import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from "redux-thunk"

import "./custom-bootstrap.css";
// Import blueprint css
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import 'filepond/dist/filepond.min.css';
import './index.scss';

import MainApp from './containers/Main';
import rootReducer from './reducers'

import * as serviceWorker from './serviceWorker';


const middlewares = [ReduxThunk];
const store = createStore(rootReducer, applyMiddleware(...middlewares));

render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
