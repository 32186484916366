import axios from 'axios';
let apiUrl = '';
if(process.env.NODE_ENV === "development"){
  apiUrl = `http://localhost/filepond-server-php/`;
}else {
  apiUrl = `/api.php/`;
}
export const API_BASE_URL = apiUrl;

//Roaster list
export const FETCH_DIRECTORIES = 'FETCH_DIRECTORIES';
export const FETCH_DIRECTORIES_SUCCESS = 'FETCH_DIRECTORIES_SUCCESS';
export const FETCH_DIRECTORIES_FAILURE = 'FETCH_DIRECTORIES_FAILURE';

export function fetchRootDirectories() {
  return dispatch => {
    dispatch(fetchDirectoriesRequest());
    axios({
      method: 'GET',
      //data: {roaster: roaster},
      url: `${API_BASE_URL}?directory_info=true`
    }).then((response) => {
      (!response.data.error ) ? dispatch(fetchDirectroySuccess(response.data.root_directories)) :
      dispatch(fetchDirectoryFailure(response.data));
    })
    .catch(error => {
      if(error.response && error.response.data){
        dispatch(fetchDirectoryFailure(error.response.data));
      }else{
        dispatch(fetchDirectoryFailure("Could not connect to API server"));
      }
    });
  }
}

export function fetchDirectoriesRequest() {
  return {
    type: FETCH_DIRECTORIES
  }
}

export function fetchDirectroySuccess(directories) {
  return {
    type: FETCH_DIRECTORIES_SUCCESS,
    payload: directories
  }
}

export function fetchDirectoryFailure(errors) {
  return {
    type: FETCH_DIRECTORIES_FAILURE,
    payload: errors
  }
}
