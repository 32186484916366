import React, { Component, Fragment } from 'react';
import { FilePond, File } from 'react-filepond';
import { Position, Toaster, Button } from "@blueprintjs/core";
import { Field, reduxForm, Form } from "redux-form";
import DropdownInput from "../components/inputs/dropdown";
import './App.css';

const appToaster = Toaster.create({
  className: "hire-toaster",
  position: Position.BOTTOM_LEFT
});

export const successToast = props => {
  appToaster.show({
    intent: "success",
    icon: "tick-circle",
    ...props
  });
};

export const failureToast = props => {
  appToaster.show({
    intent: "danger",
    icon: "delete",
    ...props
  });
};

class HomeContainer extends Component {

  constructor(props) {
        super(props);
        this.state = {
            files: [],
            parent_folder: '',
            sub_folder: '',
            childFolders: []
        };
    }

  onError(response) {
    failureToast({
        message: `Files coult not uploaded!`
    });
  }
  Onload(response, file, pond) {
    successToast({
        message: `Files has uploaded!`
    });
    const fileId = this.state.files[file.name];
    if(fileId){
      pond.removeFile(fileId);
    }
  }

  onaddFile = (error, file) => {
    let files = this.state.files;
    files[file.file.name] = file.id;
    this.setState({ files: files })
  }

  submitHandler = (event) => {
    event.preventDefault();
    //dispatch(form.reset());
    //const { leaveRequest } = this.props;
    //this.props.createLeaveRequestMessage(values, leaveRequest);
    if(!this.state.parent_folder){
      alert("Please select Parent Folder first");
      return false;
    }
    if(this.pond.getFiles().length <= 0){
      alert("Please select some folders");
      return false;
    }
    //console.log("still coming here");
    this.pond.processFiles();
  }

  onChangeSubFolderHandler = (e) => {
    this.setState({ sub_folder: e.target.value })
  }

  changeParentFolderHandler = (value) => {
    this.setState({ parent_folder: value });
    let subFolders = [];
    const { uploaders } = this.props;
    uploaders.rootDirs.map((dir) => {
      if(dir.parent === value){
        return subFolders = dir.sub
      }
      return dir
    });
    this.setState({ childFolders: subFolders });
  }

  renderParentFolders = (rootDirs) => {
    if(!rootDirs)
      return(<div></div>)
    let options = [];
    rootDirs.map((dir) => {
        return options.push({title: dir.parent, value: dir.parent });
      }
    )

    return(
      <Field
        component={DropdownInput}
        options={options}
        onChange={this.changeParentFolderHandler}
        name="parent_folder"
        placeholder="Select Parent Folder"
        fill
      />
    )
  }
  renderChildFolderOptions() {
    let options = [];
    this.state.childFolders.map((childfolder) => {
      return options.push(
        <option value={childfolder.value} key={childfolder.value}>
        {childfolder.title}
        </option>
      )
    })
    return(options)
  }

  renderChildFolders(rootDirs){
    return(
      <div className="bp3-select bp3-fill">
      <select onChange={this.onChangeSubFolderHandler} name="sub_folder">
      <option value="" defaultValue>Select Sub Folder</option>
      {
        this.state.childFolders.map((childfolder) => {
          return <option value={childfolder.value} key={childfolder.value}>
          {childfolder.title}
          </option>
        })
      }
      </select>
      </div>
    )
  }

  render() {
    const { uploaders } = this.props;
    let apiUrl = '';
    if(process.env.NODE_ENV === "development"){
      apiUrl = `http://localhost/filepond-server-php/`;
    }else {
      apiUrl = `/api.php/`;
    }
    return (
      <Fragment>
        <div className="container pt-5">
        <Form
                onSubmit={this.submitHandler}
                className="form-inline col-xs-12 col-md-10 col-lg-7" style={ { margin: '0 auto' } }>
                <div className="form-group  mb-2 ml-2">
                  { this.renderParentFolders(uploaders.rootDirs) }
                </div>
                <div className="form-group  mb-2 ml-2">
                  { this.state.childFolders.length > 0 && this.renderChildFolders() }
                </div>

          <div className="row">
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6" style={ { margin: "0 auto" } }>
            <header className="App-header pt-5">
              <p className="text-center">
                Please select language folders only.
              </p>
            </header>
            <FilePond ref={ref => this.pond = ref}
              allowMultiple={true}
              allowDrop={false}
              instantUpload={false}
              onaddfile={this.onaddFile}
              server={
                {
                url: apiUrl,
                fetch: null,
                revert: null,
                process: {
                  url: "/",
                  method: 'POST',
                  onerror: this.onError,
                  onload: (response, file) => { this.Onload(response, file, this.pond) },
                  ondata: (formData, file) => {
                    formData.append('directory', file.webkitRelativePath);
                    formData.append("parent_folder", this.state.parent_folder);
                    formData.append("sub_folder", this.state.sub_folder);
                    return formData;
                  }
                }}
              }>
              {this.state.files.map(file => (
                  <File key={file} src={file} origin="local" />
              ))}
            </FilePond>
            </div>
          </div>
          <div className="form-group  mb-2 ml-2">
            <Button type="submit" className="rounded btn-md">Upload Folders</Button>
          </div>
          </Form>
        </div>
      </Fragment>
    );
  }
}

function initialValues(){
  // const currentTime = new Date();
  // return {
  //   reportYear: currentTime.getFullYear(),
  //   reportMonth: currentTime.getMonth()
  // }
}

export default (reduxForm({
  form: "upload_form",
  initialValues: initialValues()
})(HomeContainer));
