import React, { Component } from "react";
import HomeContainer from './HomeContainer';
import { connect } from 'react-redux';
import { fetchRootDirectories } from '../actions';
import Layout from './layout';

class MainApp extends Component {

  componentDidMount() {
    this.props.fetchRootDirectories();
  }

  render(){
    const { uploaders } = this.props;
    if(!uploaders.roorDirs && uploaders.loading)
      return(
        <Layout>
          <div className="container">
            <div className="row">
              <div className="col-xs-4" style={ { margin: "0 auto" } }>
                <h3>Loading...</h3>
              </div>
            </div>
          </div>
        </Layout>
      )
    if(uploaders && uploaders.error)
      return(
        <Layout>
          <div className="container">
            <div className="row">
              <div className="col-xs-4" style={ { margin: "0 auto" } }>
                <h3>{ uploaders.error }</h3>
              </div>
            </div>
          </div>
        </Layout>
      )
    return(
      <Layout>
        <HomeContainer uploaders={uploaders} />
      </Layout>
    );
  }

}

const mapStateToProps = ({
  uploaders
}) => {
  return {
    uploaders
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchRootDirectories: () => {
    dispatch(fetchRootDirectories());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
