import React, { Fragment, PureComponent } from "react";
import classnames from "classnames";
import { Select } from "@blueprintjs/select";
import {
  Button,
  Classes,
  Icon,
  MenuDivider,
  MenuItem,
  TagInput
} from "@blueprintjs/core";

import { Option, ContextProvider, supressEvent, fragmentWrap } from "./helpers";

class CustomSelect extends PureComponent {
  items = {};
  state = {
    items: [],
    addItem: (title, value, isHeader, icon, description) => {
      this.items[value] = {
        title,
        value,
        isHeader,
        icon,
        description
      };
    }
  };

  componentWillUnmount() {
    this.items = {};
  }

  componentDidMount() {
    const itemValues = Object.keys(this.items);
    const items = [];

    itemValues.forEach(value => {
      items.push(this.items[value]);
    });

    this.setState({ items });
  }

  render() {
    const {
      className,
      children,
      input,
      meta,
      required,
      fill,
      filterable,
      useTagInput,
      multiple,
      minimal,
      large,
      popoverProps,
      ...rest
    } = this.props;
    const { items } = this.state;
    const shouldRenderTags = multiple && useTagInput;
    let text = this.items[""];

    if (Array.isArray(input.value)) {
      text =
        input.value.map(v => this.items[v] && this.items[v].title).join(", ") ||
        (this.items[""] && this.items[""].title);
    } else {
      text = this.items[input.value] && this.items[input.value].title;
    }

    return (
      <Fragment>
        <ContextProvider value={this.state}>{children}</ContextProvider>
        <Select
          resetOnClose
          inputProps={{ value: input.value }}
          items={items}
          filterable={!!filterable}
          itemRenderer={this.renderItem}
          onItemSelect={this.handleItemClick}
          itemListPredicate={this.filterItems}
          className={classnames(className, {
            "is-invalid": meta && meta.touched && meta.error,
            [Classes.FILL]: fill
          })}
          popoverProps={{
            minimal: true,
            wrapperTagName: fill ? "div" : "span",
            targetTagName: fill ? "div" : "span",
            ...popoverProps
          }}
          {...rest}
        >
          {shouldRenderTags && this.renderTagInput()}
          {!shouldRenderTags && (
            <Button
              minimal={minimal}
              large={large}
              text={text}
              rightIcon={
                <Icon icon="double-caret-vertical" className="ml-auto" />
              }
              fill={fill}
              className={classnames({
                "justify-content-start": fill
              })}
            />
          )}
        </Select>
      </Fragment>
    );
  }

  handleItemClick = item => {
    const { multiple, input } = this.props;
    let newValue = item.value;

    // handle array value
    if (multiple) {
      if (!newValue) {
        newValue = [];
      } else if (Array.isArray(input.value)) {
        newValue = input.value.includes(item.value)
          ? input.value.filter(v => v !== newValue)
          : [...input.value, newValue];
      } else {
        newValue = [newValue];
      }
    }

    input.onChange(newValue);
  };

  renderItem = (item, { handleClick, modifiers }) => {
    const { input, insidePopover, itemClassName } = this.props;
    const value = Array.isArray(input.value) ? input.value : [input.value];

    const wrapper = insidePopover ? supressEvent : fragmentWrap;

    if (item.isHeader) {
      return <MenuDivider title={item.title} key={item.value} />;
    }

    return wrapper(
      <MenuItem
        label={value.includes(item.value) ? <Icon icon="tick" /> : ""}
        onClick={handleClick}
        text={
          <Fragment>
            {item.title}
            {item.description && (
              <Fragment>
                <br />
                <small className={Classes.TEXT_MUTED}>{item.description}</small>
              </Fragment>
            )}
          </Fragment>
        }
        icon={item.icon}
        className={itemClassName}
      />,
      item.value
    );
  };

  filterItems = (query, items) => {
    return items.filter(item => `${item.title}`.toLowerCase().includes(query));
  };

  renderTagInput = () => {
    const { input, large, leftIcon, round } = this.props;
    const values = input.value
      ? input.value.map(val => this.items[val] && this.items[val].title)
      : [];

    return (
      <TagInput
        className={classnames({ [Classes.ROUND]: round })}
        large={large}
        leftIcon={leftIcon}
        values={values}
        tagProps={{ minimal: true, onRemove: null }}
        inputProps={{ readOnly: true }}
      />
    );
  };
}

export { Option };
export default CustomSelect;
