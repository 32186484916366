import React from "react";
import CustomSelect, { Option } from "../../selects/bp-custom-select";

const DropdownInput = ({ options, placeholder, skipEmptyValue, ...rest }) => (
  <CustomSelect {...rest}>
    {!skipEmptyValue && (
      <Option value="" disabled>
        {placeholder || "Select one"}
      </Option>
    )}
    {options.map(({ title, value, icon, description, isHeader }) => (
      <Option
        key={value === undefined ? title : value}
        icon={icon}
        value={value === undefined ? title : value}
        description={description}
        isHeader={isHeader}
      >
        {title}
      </Option>
    ))}
  </CustomSelect>
);

export default DropdownInput;
