import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import uploaders from './reducer_uploader';

const rootReducer = combineReducers({
  uploaders,
  form
});

export default rootReducer;
