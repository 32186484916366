import {
	FETCH_DIRECTORIES,
	FETCH_DIRECTORIES_SUCCESS,
	FETCH_DIRECTORIES_FAILURE
} from '../actions';
const INITIAL_STATE = {
	rootDirs: [], error:null, loading: false
};

export default function allUsers(state = INITIAL_STATE, action) {
  let error;
  switch (action.type) {
		case FETCH_DIRECTORIES_SUCCESS:
			return {
				rootDirs: action.payload,
				loading: false,
				error: null
			}
		case FETCH_DIRECTORIES_FAILURE:
			error = action.payload || action.payload.message;//2nd one is network or server down errors
			return {
				rootDirs: [],
				loading: false,
				error: error
			}
		case FETCH_DIRECTORIES:
		// {
		// 	...state,
	  //   leaveRequestMessagesList: {
		// 		leave_request_messages: state.leaveRequestMessagesList.leave_request_messages.concat(action.payload),
	  //     error: false,
		// 		loading: false
	  //   }
		// }
			return {
				...state, loading: true, error: null
			}
    default:
      return state
    }
}
