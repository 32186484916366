import React, { Fragment } from "react";

const SelectContext = React.createContext();

export const ContextProvider = SelectContext.Provider;
export const Option = ({ children, value, isHeader, icon, description }) => (
  <SelectContext.Consumer>
    {({ addItem }) => {
      addItem(children, value, isHeader, icon, description);
      return <Fragment />;
    }}
  </SelectContext.Consumer>
);

export const supressEvent = (content, key) => (
  <div onClick={e => e.stopPropagation()} key={key}>
    {content}
  </div>
);

export const fragmentWrap = (content, key) => (
  <Fragment key={key}>{content}</Fragment>
);
